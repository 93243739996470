/*!
 * Bootstrap v4.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */



$primary: #851010 !default;
$dark: #333333 !default;

@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";



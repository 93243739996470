$font-family-serif:           'Merriweather', serif;
$font-family-sans-serif:      'Oswald', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-serif !default;

$red:     #c50909;


@import "partials/bootstrap";

@import "partials/variables";
@import "partials/mixins";
@import "partials/navbar";
@import "partials/masthead";
@import "partials/footer";



@import "partials/global";
//@import "partials/animations";
//@import "partials/fonts";
//
//@import "partials/navbar";
//@import "partials/projects";
//@import "partials/custom";
@import "partials/swiper";

//@import "ionicons/ionicons";

@import "partials/portfolio";
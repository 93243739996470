            footer.footer {
                background-color: #1e1e1e;
                color: white;
                height: auto;
                padding: 10px 0;
            }
            .footer {
                margin: 0 auto;
                padding: 15px 0;
            }
            
            .footer-istituzionale .text a {color:#666;}
            footer.footer {
                color: white;
                height: auto;
                padding: 10px 0;
            }
            footer.footer div.credits {
                text-align: right;
                color: #666;
                font-size: 11px;
                text-transform: uppercase;
            }
            footer.footer  div.copyright {
                color: #666;
                font-size: 11px;
                text-transform: uppercase;
            }
            footer.footer div.footer-istituzionale {
                padding: 15px;
            }
            footer.footer div.footer-istituzionale img {
                display: block;
                margin-bottom: 12px;
            }
            footer.footer div.footer-istituzionale ul#menu-footer-menu {
                display: block;
                margin-bottom: 20px;
                border-left: 1px solid #999;
                padding-left: 0;
            }
            footer.footer div.footer-istituzionale ul#menu-footer-menu li {
                    border-right: 1px solid #999;
                    line-height: 1em;
                    float: left;
                    padding: 0 5px;
                    list-style: none;
            }
            footer.footer div.footer-istituzionale ul#menu-footer-menu li a {
                cursor: pointer;
                color: #999;
                line-height: 1em;
                text-decoration: none;
                font-size: 11px;
                font-weight: 400;
            }
                div.text {
                    font-size: 11px;
                    color: #666;
                }
        

// Styling for the navbar
.navbar {
    padding: .5rem 1rem .5rem 1rem;
    flex-wrap: nowrap;
}

#mainNav {
  border-color: fade-out($gray-darker, .95);
  background-color: white;
  @include sans-serif-font;
  @include transition-all;
  .navbar-brand {
    font-weight: 700;
    text-transform: uppercase;
    color: $theme-primary;
    @include sans-serif-font;
      overflow:hidden;
      svg {
          width:300px;
          height:70px;
      }
        .logo-mrd {
            width:300px;
            fill: #0078b7;
            &:hover {
                fill: #0078b7;
            }
    }
    &:focus,
    &:hover {
      color: darken($theme-primary, .1);
    }
  }
  .navbar-toggle {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: $gray-darker;
  }
    
  .navbar-nav {
    > li.nav-item {
       > a.nav-link:focus,
      > a.nav-link {
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        color: $gray-darker;
        &:hover {
          color: $theme-primary;
        }
        &.active {
          color: $theme-primary !important;
          background-color: transparent;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
nav.navbar-shrink {
    border-top: 20px solid #fff!important;
}
    
    
  @media (min-width: 992px) {
    border-color: fade-out(white, .7);
    background-color: transparent;
    .navbar-brand {
      color: fade-out(white, .3);
      width:300px;
      overflow:hidden;
        svg {
          width:400px;
            height:90px;
      }
    .logo-mrd {
        width:100%;
        fill: #ffffff;
        &:hover {
            fill: #0078b7;
        }
    }
      &:focus,
      &:hover {
        color: white;
      }
    }
    .navbar-nav > li.nav-item > a.nav-link,
    .navbar-nav > li.nav-item > a.nav-link:focus {
      color: fade-out(white, .3);
      &:hover {
        color: white;
      }
    }
    &.navbar-shrink {
        //border-color: fade-out($gray-darker, .95);
        box-shadow: 0px 1px 20px 0px rgba(0,0,0,0.4);
      background-color: white;
      .navbar-brand {
        font-size: 16px;
        color: $theme-primary;
          //width:300px;
          svg {
          width:300px;
      }
        .logo-mrd {
            fill: #0078b7;
            /*&:hover {
                fill: #333333;
            }*/
        }
        &:focus,
        &:hover {
          color: darken($theme-primary, .1);
        }
      }
      .navbar-nav > li.nav-item > a.nav-link,
      .navbar-nav > li.nav-item > a.nav-link:focus {
        color: $gray-darker;
        &:hover {
          color: $theme-primary;
        }
      }
    }
  }
}

.link-top {
    text-align:right;
    padding-bottom:12px;
    .navbar-shrink & {
            padding-bottom:18px;
        }
    .head-anchor {
        color:#fff;
        display:block;
        font-size:.9rem;
        line-height:1.5;
        .navbar-shrink & {
            color:$danger!important;
        }
    }
}

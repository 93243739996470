/*
.masthead {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 35rem;
  padding: 25rem 0 5rem 0;
   
background-color:#635E3C;  
background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  h1 {
    
    font-size: 1.6rem;
    line-height: 1;
    
  }
  h2 {
   
  }
  @media(min-width: 768px) {
    h1 {
      font-size: 4rem;
      line-height: 4rem;
    }
  }
  @media(min-width: 992px) {
    
    h1 {
      font-size: 2.5rem;
      line-height: 1;
      
    }
    h2 {
      
    }
  }
}
*/

// Styling for the masthead
header.masthead {
  padding-top:120px;
  @media (min-width: 768px) {
    padding-top:160px;
    }
  position: relative;
  width: 100%;
  min-height: auto;
  text-align: center;
  color: white!important;
  background-position: center;
  background-color:$theme-primary;
  @include background-cover;
    .logo-mrd {
        width:100%;
        fill: #ffffff;
    }
    .cover-big { 
        //border:1px solid #ccc;
        margin-bottom:2rem;
    }
    h1 {
        font-size:3rem;
        line-height: 3rem;
        font-weight: 400;
        margin-top: 1rem;
        margin-bottom: 1rem;
        color:#fff;
      }
    h2 {
        font-size:1.6rem;
        line-height: 2.4rem;
        font-weight: 900;
        margin-top: 0;
        margin-bottom: 3rem;
        text-transform: uppercase;
        color:#fff;
      }
    h4 {
        //font-size:.8rem;
        line-height: 2rem;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 0;
        text-transform: uppercase;
        color:#fff;
      }
  .header-content {
    position: relative;
    width: 100%;
    padding: 150px 15px 100px;
    text-align: center;
    .header-content-inner {
      h1 {
        font-size: 30px;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 0;
        color:#fff;
        
      }
      hr {
        margin: 30px auto;
      }
      p {
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 50px;
        color: fade-out(white, .3);
        color:#fff!important;
      }
    }
  }
  @media (min-width: 768px) {
      h1 {
        font-size:3.2rem;
        line-height: 2.8rem;
        font-weight: 400;
        margin-top: 1rem;
        margin-bottom: 1rem;
        color:#fff;
      }
        h4 {
        font-size:1.8rem;
        line-height: 2rem;
        font-weight: 900;
        margin-top: 0;
        margin-bottom: 0;
        text-transform: uppercase;
        color:#fff;
      }
    height: auto;
    min-height: 600px;
    .header-content {
      position: absolute;
      top: 150px;
      padding: 0 50px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      .header-content-inner {
        max-width: 1000px;
        margin-right: auto;
        margin-left: auto;
        h1 {
          //font-size: 120px;
        }
        p {
          font-size: 1.4rem;
          max-width: 80%;
          margin-right: auto;
          margin-left: auto;
          color:#fff!important;
        }
      }
    }
  }
}


.intro-cit {
    border:0px;
    h2 {
        font-size:2rem!important;
        font-weight:400!important;
        //line-height: 1!important;
    }
    p {
        font-size:1.2rem
            
    }
}
@media (min-width: 768px) {
    .intro-cit {
    border-top:2px solid rgba(255,255,255,.3)!important; 
    border-bottom:2px solid rgba(255,255,255,.3)!important; 
    }
}

.txt-desc {
    text-align: center;
    @media (min-width: 768px) {
        text-align: left;
    }
}

#editoriale {
    //padding-top:40px;
    @media (min-width: 768px) {
        //padding-top:200px;
    }
    p.editoriale {
      font-size: 1rem;
      line-height: 1.618rem;
      margin-bottom: 20px;
      color: $theme-primary!important;
    }
    h4 {
        color:$evidence;
        font-weight: 400;
        font-size:2rem;
    }
    h2 {
        font-weight: 400;
        font-size:3rem;
    }
    
}

.viewMore {
    font-weight: 600;
    color:$evidence;
}
.viewMore[aria-expanded="false"]:before {
    content: "+ Leggi di più";
}
        
.viewMore[aria-expanded="true"]:before {
    content: "- Chiudi";
}

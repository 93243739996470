// Global styling for this template
body {
    /*background-image: url(/img/bg-body.jpg);
    background-repeat: no-repeat;
    background-position: center 800px;*/
    //font-family: $serif-font!important;
}
body,
html {
  width: 100%;
  height: 100%;
}

body {
  //@include serif-font;
    font-size:18px;
}

hr {
  max-width: 50px;
  border-width: 3px;
  border-color: $theme-primary;
}

hr.light {
  border-color: white;
}

a {
  color: $theme-primary;
  @include transition-all;
  &:focus,
  &:hover {
    color: darken($theme-primary, .1);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-sans-serif !important;
  //color: #cc0000;
}

p {
  font-size: 21px;
  line-height: 1.6;
  margin-bottom: 20px;
}

.bg-primary {
  //background-color: $theme-primary !important;
}

.bg-dark {
  color: white;
  //background-color: $gray-darker !important;
}

.text-faded {
  color: fade-out(white, .3);
}

section {
  //padding-top: 100px;
}

.section-heading {
  margin-top: 0;
  margin-bottom:2rem;
  color:$evidence;
  font-weight:400;
}

::-moz-selection {
  color: white;
  background: $gray-darker;
  text-shadow: none;
}

::selection {
  color: white;
  background: $gray-darker;
  text-shadow: none;
}

img::selection {
  color: white;
  background: transparent;
}

img::-moz-selection {
  color: white;
  background: transparent;
}

body {
  -webkit-tap-highlight-color: $gray-darker;
}